body {
  font-family: "Nunito", sans-serif;
}

.radio-headline {
  display: flex;
  flex-direction: row;
}
.padding-8 {
  padding: 8px;
}

.vh-full {
  height: 98vh;
  overflow: hidden;
}

.main-container {
  margin: 4px 8px;
  height: 93%;
}


.drop-zone {
  height: 350px;
  width: 50%;
  display: flex;
  flex-direction: column;
  background-color: #fafafa;
  border: 3px dotted #ccc;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.drop-zone-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.zipper {
  font-size: 98px;
  opacity: 0.5;
}

ul.tiny li a {
  font-size: 8px;
}

ul.file-tree {
  margin: 0 15px;
}

.dirpic {
  padding: 3px 15px;
  margin: 0 8px 0 0;
  border-radius: 8px;
}

.file-tree-entry {
  padding: 3px 5px;
  border-radius: 8px;
  cursor: pointer;
}
.file-tree-entry:hover {
  background-color: #ebfffc;
  color: #00947e;
}

.file-tree-entry.full {
  font-weight: bold;
  background-color: #ebfffc;
}

.filepic {
  padding: 7px;
  margin: 0 8px 0 0;
  border-radius: 35px;
}

.filepic.pdf {
  background-color: #f14668;
  color: #fff;
  border: 1px solid #ccc;
}

.filepic.csv {
  background-color: #ffdd57;
  color: #777;
  border: 1px solid #fff;
}

.wide-screen {
  width: 90% !important;
}

.collection-head {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.collection-head div {
  margin-left: 3px;
}

.collection-buttons {
  visibility: hidden;
}

.collection-row:hover .collection-buttons {
  visibility: visible;
}

.collection-sidebar-wrapper {
  min-width: 250px;
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.collection-sidebar-wrapper span {
  margin-left: 16px;
}

td.custom-td,
th.custom-td {
  min-width: 184px;
}

.extend-page {
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.is-tiny {
  font-size: 10px !important;
  text-transform: uppercase;
}

.table-tag {
  font-size: 1em !important;
}

.is-draggable {
  cursor: grab;
}

.tag.is-taken {
  opacity: 0.3;
  cursor: block;
}

.i-warning {
  margin-left: 7px;
  font-size: 18px !important;
}

.table-divider {
  background-color: #fafafa;
}

.converter-status {
  width: 50px;
}

.login-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  width: 100%;
  padding-top: 5%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.new-button {
  margin: 10px 0 0 35px;
}

i.is-large {
  font-size: 1.5em;
  margin: 0 8px 0 8px;
}


.item-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-shrink: 1;
}


.analyze-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.progress-container {
  margin-left: 90px;
  flex: 3;
}

.thumbnail {
  border-radius: 5px;
}

.textpic-wrapper {
  margin: 17px 0;
}
.textpic-wrapper img {
  border-radius: 15px;
}

.modal-card-body .main-picture {
  border-radius: 15px;
}

.modal-card-body .thumb-link {
  border-radius: 8px;
  margin: 3px;
  cursor: pointer;
}

.input-text {
  min-height: 300px;
}

.p-paragraph {
  margin: 15px 0;
}

.progress-percentage {
  width: 80px;
}
.status-row {
  min-height: 60px;
}

.sentence-p {
  margin: 10px 0;
}

.speech-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 3px 8px;
}

.is-draggable {
  cursor: grab;
}

.main-button {
  margin: 0 0 5px 0;
}

.empty-notification {
  margin: 35px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.large-icon {
  margin-right: 15px;
}

.large-icon i {
  font-size: 1.8em;
}

.progress-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.bar-container {
  flex:3;
}
.frame-percentage {
  margin: 0 15px;
}

.course-headline {
  font-size: 1.5em;
}

.feedback-top-bar {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  align-items: center;
  justify-content: flex-start;
}

.feedback-top-bar span {
  margin-left: 15px;
}

.feedback-top-bar i {
  font-size: 1.5em;
  cursor: pointer;
}

.tag.left {
  border-left: 1px solid #ccc;
}

.notification.illustrate {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.question-span {
  margin-right: 15px;
}

.question-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between
}

.answer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.loader-container {
  height: 90vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.headline-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.headline-wrapper span {
  margin-right: 35px;
}

.headline-wrapper span i {
  font-size: 1.5em;
}

.icon.is-large {
  font-size: 1.5em;
}

.tags-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.fade-between {
  animation: fadeInAnimation ease 0.3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
}

.round-button {
  position: fixed;
  bottom: 34px;
  right: 5%;
  z-index: 999;
}

.round-button i {
  font-size: 1.5em;
}

.round-button-circle {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: #aa0000;
  transition: all 0.3s ease-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

.feedback-has-read {
  margin: 0 0 10px 0;
}

.tiny-tags span {
  font-size: 10px !important;
}

.feedback-top-bar .headline {
  font-size: 1.5em;
}

.projects-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.project-wrapper {
  position: relative;
  height: 200px;
  width: 150px;
  margin: 3px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 .5em 1em -.125em rgba(10,10,10,.1),0 0 0 1px rgba(10,10,10,.02);
  color: #4a4a4a;
  display: block;
  padding: 1.25rem;
  cursor: pointer;
}

.project-wrapper .thumb {
  height: 100px;
  width: 100px;
  border-radius: 6px;
}

.project-wrapper .top-right {
  position: absolute;
  top: 3px;
  right: 3px;
}

.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;
  width: 100%;
}

.done-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.switch-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.vh80 {
  height: 80vh;
}

.is-rtl {
  direction: rtl;
}

.is-ltr {
  direction: ltr;
}

  

.process-stage {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 15px 60px;
  width: 90%;
}

.process-stage:first-child {
  margin-top: 5px;
}

.button-container {
  flex: 3;
}

.icon-wrapper {
  width: 50px;
}

.process-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 15px 35px;
  width: 350px;
}

.process-button-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.space-around {
  justify-content: space-around !important;
}

.space-between {
  justify-content: space-between !important;
}

.process-placeholder {
  width: 34px !important;
}

.top-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.title-container { 
  display: flex;
  flex-direction: row;
  width: 100%;
}

.title-container.centered {
  justify-content: center;
}

.notifier {
  z-index: 30;
  position: absolute;
  bottom: 5%;
  left: 5px;
  right: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.notifier div {
  display: flex;
  flex-direction: column;
  width: 90%;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.quiz-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.lesson-top-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.lesson-top-bar .icon {
  font-size: 1.5em;
  cursor: pointer;
}

.art-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  cursor: pointer;
}

.art-image-wrapper {
  margin: 0 15px 0 0;
}
.art-image-wrapper img {
  border-radius: 8px;
}

.textpic-container {
  height: 300px;
  width: 300px;
  position: relative;
}

.textpic-container img {
  width: 300px;
  height: 300px;
  border-radius: 35px;
  border: 1px solid #ccc;
}

.textpic-container .textpic-redo {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0px;
  right: 0px;
  background-color: rgba(35,35,35,.5);
  z-index: 999;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.textpic-container .textpic-redo i {
  font-size: 2em;
  color: #fff;
}


.item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 17px 8px;
  padding: 3px;
  border: 1px solid #efefef;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  border-radius: 0px;
  cursor: pointer;
}

.item .item-pos {
  font-size: 10px;
  text-transform: lowercase;
  font-weight: bold;
}

.item .item-translated {
  font-size: 15px;
  font-weight: bold;
}

.item .item-main {
  font-size: 1.5em;
  font-weight: bold;
}

.item .item-root {
  font-size: 15px;
}

.item .item-translate {
  font-size: 15px;
  font-weight: bold;
}

.item .item-picture {
  height: 100px;
  width: 100px;
  border: 2px solid #ccc;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.item .item-placeholder {
  height: 75px;
  width: 75px;
}

.item.pickable {
  border-radius: 8px;
  border: 1px dotted #000;
}

.item.picked {
  border: 1px dotted #00948e;
  background-color: #ebfffc;
}

.sentence.is-current {
  background-color: #ebfffc;
}

i.is-success {
  color: #0a0;
}

.add-question-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin: 15px 0;
}

.small {
  font-size: 0.6em;
}